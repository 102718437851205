<template>
    <div class="credit-form">
        <div class="container">
            <h1 class="credit-form__title">Оформление заявки</h1>
            <form class="credit-form__form" method="post" @submit.prevent="submitForm" action="/offers"
                  ref="formOffers">
                <div class="credit-form__input-wrap credit-form__input-wrap--lg">
                    <div class="credit-form__input-group">
                        <label for="sumCredit" class="credit-form__label">Сумма</label>
                        <input type="number" id="sumCredit" placeholder="Введите сумму" v-model="form.amount"
                               class="credit-form__input credit-form__input--big" @input.number="setSum($event.target.value)"
                               autocomplete="none" @blur="$v.form.amount.$touch()"
                               :class="{ 'error-feedback': $v.form.amount.$error}">
                        <button type="button" v-if="!$v.form.amount.minLength||!$v.form.amount.between&&form.amount!==''" class="error-feedback__btn"
                                @click="form.amount = ''"></button>
                        <div v-if="form.amount==''" class="invalid-feedback">Введите сумму от 1000 руб. до 100 000 руб.
                        </div>
                        <div v-else-if="!$v.form.amount.minLength" class="invalid-feedback">Минимальная сумма - 1000 руб.
                        </div>
                        <div v-else-if="!$v.form.amount.between" class="invalid-feedback">Максимальная сумма - 100 000 руб.
                        </div>
                    </div>
                    <div class="credit-form__input-group--limit">
                        <div class="credit-form__input-group credit-form__input-group--half">
                            <label for="sumCredit" class="credit-form__label">Срок кредита</label>
                            <input type="number" id="count" placeholder="Укажите срок" v-model.number="form.count"
                                   class="credit-form__input" @blur="$v.form.count.$touch()" autocomplete="nope"
                                   :class="{ 'error-feedback': $v.form.count.$error||limitMessage()!==''}" >
                            <button type="button" v-if="($v.form.count.$error||limitMessage()!=='')&&form.count!==''" class="error-feedback__btn"
                                    @click="form.count = ''"></button>

                            <div v-if="limitMessage()!==''" class="invalid-feedback">
                                {{ limitMessage() }}
                            </div>


                            <ServerError :serverErrors="serverErrors" nameField="count"/>
                        </div>
                        <div class="credit-form__input-group credit-form__input-group--half">
                            <label for="sumCredit" class="credit-form__label">Ед. времени</label>
                            <SelectTerm id="unit" name="unit" :selTerm="form.term.unit" :count="Number(form.count)" class="custom-select" @change="changeUnit" />
                        </div>
                    </div>
                </div>
                <fieldset>
                    <div class="credit-form__input-wrap">
                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="lastName" class="credit-form__label">Фамилия</label>

                            <VueSuggestions
                                    :model.sync="$v.form.lastName.$model"
                                    :value="''"
                                    class="form-control"
                                    :options="lastNameOptions"
                                    id="lastName"

                                    autocomplete="nope"
                                    placeholder="Введите фамилию"
                                    :class="{ 'error-feedback': $v.form.lastName.$error}"
                            >
                            </VueSuggestions>
                            <button type="button" v-if="form.lastName!==null&&$v.form.lastName.$error&&form.lastName!==''" class="error-feedback__btn"
                                    @click="form.lastName = ''"></button>

                            <div v-if="$v.form.lastName.$error" class="invalid-feedback">Фамилия может содержать только русские буквы, тире и пробелы.
                            </div>
                            <ServerError :serverErrors="serverErrors" nameField="lastName"/>

                        </div>

                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="firstName" class="credit-form__label">Имя</label>

                            <VueSuggestions

                                    :model.sync="$v.form.firstName.$model"
                                    :value="''"
                                    class="form-control"
                                    :options="firstNameOptions"
                                    id="firstName"
                                    autocomplete="nope"
                                    placeholder="Введите имя"
                                    @blur="$v.form.firstName.$touch();"
                                    :class="{'error-feedback': $v.form.firstName.$error }"
                            >
                            </VueSuggestions>
                            <button type="button" v-if="form.firstName!==null&&form.firstName!==''&&$v.form.firstName.$error" class="error-feedback__btn"
                                    @click="form.firstName = ''"></button>
                            <div v-if="$v.form.firstName.$error||updGender()" class="invalid-feedback">Имя может содержать только русские буквы, тире и пробелы.
                            </div>
                            <ServerError :serverErrors="serverErrors" nameField="firstName"/>
                            <input type="hidden" class="credit-form__input" placeholder="Введите пол" autocomplete="nope"
                                   v-model="form.gender" id="gender" name="gender"
                                   @blur="$v.form.gender.$touch()"
                                   :class="{ 'error-feedback': $v.form.gender.$error}">
                        </div>
                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="middleName" class="credit-form__label">Отчество</label>

                            <VueSuggestions
                                    :model.sync="$v.form.middleName.$model"
                                    :value="''"
                                    class="form-control"
                                    :options="middleNameOptions"
                                    id="middleName"
                                    autocomplete="nope"
                                    placeholder="Введите отчество"
                                    @blur="$v.form.middleName.$touch()"
                                    :class="{'error-feedback': $v.form.middleName.$error }"
                            >
                            </VueSuggestions>
                            <button type="button" v-if="form.middleName!==null&&form.middleName!==''&&$v.form.middleName.$error" class="error-feedback__btn"
                                    @click="form.middleName = ''"></button>
                            <div v-if="$v.form.middleName.$error" class="invalid-feedback">Отчество может содержать только русские буквы, тире и пробелы.
                            </div>
                            <ServerError :serverErrors="serverErrors" nameField="middleName"/>

                        </div>
                        <div class="credit-form__input-group credit-form__input-group--date" :class="{ 'error-feedback': $v.form.birthDate.$error||form.age<18}">
                            <label for="birthDate" class="credit-form__label">Дата рождения</label>
                            <input type="text" class="credit-form__input credit-form__input--datapick"
                                   autocomplete="nope"
                                   placeholder="дд.мм.гггг"
                                   v-model="form.birthDate"
                                   id="birthDate" name="birthDate"
                                   maxlength="10"
                                   @blur="$v.form.birthDate.$touch();getAge($event.target.value);"
                            >
                            <date-pick ref="datepicker" v-model="form.birthDate" :weekdays="weekdays"
                                       class="credit-form__inputv credit-form__input--datapickcomponent dpicker"
                                       :selectableYearRange="selectableYearRange" :months="months" :format="format"
                                       :inputAttributes="inputAttributes"
                            ></date-pick>
                            <svg width="26" viewBox="0 0 26 25" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" @click="toggleDatapick" class="dpicker">
                                <path class="dpicker" d="M22.5714 2.32558H15.9047V0H14.1905V2.32558H8.38097V0H6.66669V2.32558H0V6.10465H22.5714V2.32558Z"
                                      fill="#DCE8ED"/>
                                <path class="dpicker" d="M24.2859 5.81395V21.5116V23.2558H22.5716H3.42871V25H26.0001V5.81395H24.2859Z"
                                      fill="#DCE8ED"/>
                                <path class="dpicker" d="M22.5714 7.84883H0V21.5116H22.5714V7.84883ZM7.57143 18.75H4.71429V15.843H7.57143V18.75ZM7.57143 13.5174H4.71429V10.6105H7.57143V13.5174ZM12.7143 18.75H9.85714V15.843H12.7143V18.75ZM12.7143 13.5174H9.85714V10.6105H12.7143V13.5174ZM17.8571 18.75H15V15.843H17.8571V18.75ZM17.8571 13.5174H15V10.6105H17.8571V13.5174Z"
                                      fill="#DCE8ED"/>
                            </svg>
                            <div v-if="$v.form.birthDate.$error" class="invalid-feedback">Пожалуйста, введите
                                реальную дату в формате дд.мм.гггг
                            </div>
                            <div v-else-if="form.age<18 " class="invalid-feedback">Вам должно быть более 18 лет.
                            </div>

                            <ServerError :serverErrors="serverErrors" nameField="birthDate"/>
                        </div>
                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="citi" class="credit-form__label">Город</label>
                            <VueSuggestions
                                    :model.sync="$v.form.city.$model"
                                    :coordinates.sync="coordinates"
                                    :value="''"
                                    class="form-control"
                                    :options="citiesOptions"
                                    id="citi"

                                    autocomplete="nope"
                                    placeholder="Выберите город проживания"
                                    :class="{ 'error-feedback': $v.form.city.$error}"
                            >
                            </VueSuggestions>
                            <button type="button" v-if="form.city!==null&&form.city!==''&&$v.form.city.$error" class="error-feedback__btn"
                                    @click="form.city = ''"></button>
                            <div v-if="$v.form.city.$error||updCityKladr()" class="invalid-feedback">Укажите город, где вы проживаете.
                            </div>
                            <div class="invalid-feedback city-select-nothing"></div>
                            <input type="hidden" class="credit-form__input" placeholder="Введите пол" autocomplete="nope"
                                   v-model="form.cityKladr" id="cityKladr" name="cityKladr"
                                   @blur="$v.form.cityKladr.$touch()"
                                   :class="{ 'error-feedback': $v.form.cityKladr.$error}">
                            <ServerError :serverErrors="serverErrors" nameField="city"/>
                        </div>
                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="region" class="credit-form__label">Регион</label>
                            <VueSuggestions
                                    :model.sync="$v.form.region.$model"
                                    :coordinates.sync="coordinates"
                                    :value="''"
                                    class="form-control"
                                    :options="regionOptions"
                                    id="region"
                                    ref="region"
                                    readonly="readonly"
                                    disabled="disabled"
                                    autocomplete="nope"

                                    :class="{ 'error-feedback': $v.form.region.$error}"
                            >
                            </VueSuggestions>
                            <button type="button" v-if="form.region!==null&&form.region!==''&&$v.form.region.$error" class="error-feedback__btn"
                                    @click="form.region = ''"></button>
                            <div v-if="$v.form.region.$error" class="invalid-feedback">Укажите регион, где вы проживаете.
                            </div>

                            <ServerError :serverErrors="serverErrors" nameField="region"/>
                        </div>

                        <div class="credit-form__input-group credit-form__input-group--dadata">
                            <label for="email" class="credit-form__label">Email</label>
                            <VueSuggestions

                                    :model.sync="$v.form.email.$model"
                                    :coordinates.sync="coordinates"
                                    :value="''"
                                    class="form-control"
                                    :options="emailOptions"
                                    id="email"
                                    type="email"
                                    autocomplete="nope"
                                    placeholder="Введите Ваш email"
                                    @blur="$v.form.email.$touch()"
                                    :class="{'error-feedback': $v.form.email.$error }"
                            >
                            </VueSuggestions>

                            <button type="button" v-if="$v.form.email.$error&&form.email!==''&&form.email!==null"
                                    class="error-feedback__btn"
                                    @click="form.email = ''"></button>

                            <div v-if="$v.form.email.$error" class="invalid-feedback">
                                Пожалуйста, введите реальный email
                                адрес

                            </div>
                            <ServerError :serverErrors="serverErrors" nameField="email"/>
                        </div>
                        <div class="credit-form__input-group">
                            <label for="phone" class="credit-form__label">Мобильный тел.</label>
                            <input type="tel"
                                   class="credit-form__input"
                                   placeholder="+7 (___) ___-__-__"
                                   autocomplete="nope"
                                   v-model="form.phone"
                                   id="phone"
                                   name="phone"
                                   @blur="$v.form.phone.$touch()" :class="{ 'error-feedback': $v.form.phone.$error}">
                            <button type="button" v-if="form.phone!==null&&form.phone!==''&&$v.form.phone.$error" class="error-feedback__btn"
                                    @click="form.phone = ''"></button>
                            <div v-if="$v.form.phone.$error" class="invalid-feedback">Пожалуйста, введите номер
                                телефона в формате +7 (xxx) xxx-xx-xx
                            </div>
                            <ServerError :serverErrors="serverErrors" nameField="phone"/>
                        </div>



                        <div class="credit-form__checkbox-group">
                            <input type="checkbox" id="checkbox" v-model="form.acceptTerms"
                                   class="credit-form__checkbox"
                                   @blur="$v.form.acceptTerms.$touch()"
                                   >
                            <label for="checkbox">Я даю свое <a href="/agreement" target="_blank">согласие на обработку персональных данных, принимаю         условия оферты
                                </a></label>
                            <span class="checkmark"></span>
                            <ServerError :serverErrors="serverErrors" nameField="acceptTerms"/>
                        </div>
                    </div>
                </fieldset>

                <p class="invalid-feedback invalid-feedback--server" v-if="serverErrors">Форма не отправлена!</p>
                <button class="credit-form__btn btn" v-if="form.showButton" type="submit" :disabled="$v.$invalid">Продолжить</button>
            </form>
        </div>
    </div>
</template>
<script>
    import { required, email, minLength, maxLength, sameAs, between } from "vuelidate/lib/validators";
    import VueSuggestions from './Form/VueSuggestions.vue';
    import RangeSlider from 'vue-range-slider';
    import SelectTerm from './Form/SelectTerm.vue';
    import DatePick from 'vue-date-pick';
    import ServerError from './Form/ServerError.vue';
    import Inputmask from "inputmask";
    export default {
        components: {
            RangeSlider,
            VueSuggestions,
            SelectTerm,
            DatePick,
            ServerError,

        },

        data() {
            return {
                form: {
                    amount: '34000',
                    lastName: '',
                    firstName: '',
                    middleName: '',
                    birthDate: null,
                    age: 18,
                    phone: null,
                    email: '',
                    count: 2,
                    region: '',
                    city: '',
                    cityKladr: '',
                    gender: 'М',
                    acceptTerms: false,
                    showButton: true,

                    term: {
                        unit: 'day'
                    },
                    acceptTerms: ''
                },
                //for data-pick
                weekdays: [
                    'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'
                ],


                months: [
                    'Январь', 'Февраль', 'Март', 'Апрель',
                    'Май', 'Июнь', 'Июль', 'Август',
                    'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'
                ],
                format: 'DD.MM.YYYY',
                hasInputElement: false,
                inputAttributes: {
                    placeholder: 'Выберите дату рождения',
                    maxlength: 10,
                    required: true

                },
                selectableYearRange: 100,

                histories: ['Никогда не брал кредитов', 'Брал кредит'],
                cities: ['Санкт-Петербург', 'Москва'],
                regions: ['Санкт-Петербург', 'Ленинградская область'],
                checked: false,
                showModal: false,
                serverErrors: null,
                serverErrorMsg: null,

                //for dadata
                coordinates: {
                    latitude: '',
                    longitude: ''
                },
                regionOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "ADDRESS",
                    hint: false,
                    bounds: "region-area",
                    minChars: 1
                },
                citiesOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "ADDRESS",
                    hint: false,
                    bounds: "city-settlement",
                    minChars: 1,
                    constraints: this.$refs.region,
                    onSelect: function() {
                        $(".city-select-nothing").text("");
                    },
                    onSelectNothing: function() {
                        $(".city-select-nothing").text("Выберите один из предложенных вариантов");
                    }
                },
                lastNameOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "NAME",
                    hint: false,
                    bounds: "surname",
                    minChars: 3,
                },
                firstNameOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "NAME",
                    hint: false,
                    bounds: "name",
                    minChars: 3,

                },
                middleNameOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "NAME",
                    hint: false,
                    bounds: "patronymic",
                    minChars: 3,

                },

                emailOptions: {
                    token: '048dadf5d41cf9ab423264ad914baecd1893540c',
                    type: "EMAIL",

                    /* Вызывается, когда пользователь выбирает одну из подсказок */
                    onSelect: function (suggestion) {
                        console.log(suggestion.value);
                        this.er = true;
                        console.log(this.er);
                    }
                }
            }
        },
        validations: {
            form: {
                amount: {
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(6),
                    between: between(1000, 100000)
                },
                count: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(30),
                    between: between(1, 365),
                    alpha: val => /^\d+$/i.test(val)

                },
                firstName: {
                    required,
                    maxLength: maxLength(35),
                    alpha: val => /^[а-яё\s '-]*$/i.test(val)
                },
                lastName: {
                    required,
                    maxLength: maxLength(35),
                    alpha: val => /^[а-яё\s '-]*$/i.test(val)
                },
                region: {
                    required,
                    alpha: val => /^[а-яА-Я]+[а-яА-Я\s\.,-:;]{1,}$/i.test(val)
                },
                city: {
                    required,
                    alpha: val => /^[а-яА-Я]+[а-яА-Я\s\.,-:;]{1,}$/i.test(val)
                },
                middleName: {
                    required,
                    maxLength: maxLength(35),
                    alpha: val => /^[а-яё\s '-]*$/i.test(val)
                },
                gender: {
                    maxLength: maxLength(10),
                    alpha: val => /^[а-яё\s '-]*$/i.test(val)
                },
                cityKladr: {
                    maxLength: maxLength(20),
                    alpha: val => /^\d+$/i.test(val)
                },
                birthDate: {
                    required,
                    maxLength: maxLength(10),
                    validDate: val => /^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.](19|20)\d\d$/i.test(val)
                },

                phone: {
                    required,
                    maxLength: maxLength(25),
                    validTel: val => /^\+7\s\(\d{3}\)\s\d{3}\-\d{2}\-\d{2}$/i.test(val)
                },
                email: {
                    required,
                    minLength: minLength(2),
                    maxLength: maxLength(40),
                    validMail: val => /^[0-9a-z-\.\_]+\@[0-9a-z-\_]{2,}\.[a-z]{2,}$/i.test(val)
                },
                acceptTerms: {
                    required
                },

            }

        },
        mounted() {

            if (localStorage.sumCredit) {
                this.form.amount = localStorage.sumCredit;
            }

            if (localStorage.limitCredit) {
                if(this.form.amount <= 30000) {
                    this.form.count = localStorage.limitCredit;
                } else {
                    this.form.count = localStorage.limitCredit / 30;
                }
            }

            this.form.term.unit = this.form.amount <= 30000 ? 'day' : 'month';

            $(".suggestions-input").each(function() {
                $(this).attr("autocomplete", "nope");
            });

            const birthDate = document.getElementById('birthDate');
            const birthMask = new Inputmask("99.99.9999");
            birthMask.mask(birthDate);

            const ph = document.getElementById('phone');
            const phMask = new Inputmask("+7 (999) 999-99-99");
            phMask.mask(ph);

            const _self = this;

            // стайлер для выпадашек месяца и года
            // закрываем календар при клике вовне и при выборе даты
            $(document).on('click', function(e) {
                try {
                    const $target = $(e.target);
                    const isOpen = $target.hasClass('dpicker') || $target.closest('.vdpHeader').length ? true : false;

                    if(!isOpen) {
                        _self.$set( _self.$refs.datepicker, 'opened', false );
                    }

                    if($('.vdpPeriodControl select').length) {

                        // обновление месяца/года во время листания по календарю
                        // $('.vdpArrow').on('click', function() {
                        //     $('.vdpPeriodControl select').trigger('refresh');
                        // });
                        // $('.vdpPeriodControl select').on('change', function() {
                        //     $(this).trigger('refresh');
                        // });

                        // обновление данных календаря при выборе месяца/года
                        $('.vdpPeriodControl select').styler({
                            onSelectClosed: function() {
                                const selectedDate = {
                                    month: Number($('.vdpPeriodControl').eq(0).find('select').val()),
                                    year: Number($('.vdpPeriodControl').eq(1).find('select').val())
                                };

                                _self.$set( _self.$refs.datepicker, 'currentPeriod', selectedDate );
                            }
                        });
                    } else {
                        $('.vdpPeriodControl select').styler('destroy');
                    }
                } catch (err) {
                    console.error(err);
                }

            });

            // стайлер для ед. измерения
            const $unit = $('#unit');
            $unit.val(this.form.term.unit);
            $('.custom-select').styler({
                onSelectClosed: function() {
                    _self.changeUnit($unit.val());
                }
            });
        },
        methods: {
            submitForm() {
                this.form.amount = this.form.amount.toString();
                this.form.showButton = false;
                this.form.showButton
                axios.post('/apply_application', this.form)
                    .then(() => {
                        ['limitCredit', 'sekectTerm', 'sumCredit'].forEach(item => localStorage.removeItem(item));
                        window.location.href = '/offers';
                    })
                    .catch((err) => {
                        this.serverErrors = err.response.data.errors;
                    });
            },
            getAge(value){
                var arrDate = value.split('.');
                var now = new Date();
                var today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
                var dob = new Date(arrDate[2], arrDate[1]-1, arrDate[0]);
                var dobnow = new Date(today.getFullYear(), dob.getMonth(), dob.getDate());
                var age;

                age = today.getFullYear() - dob.getFullYear();

                if (today < dobnow) {
                    age = age-1;
                }

                this.form.age = age;

            },
            setSum(value) {
                this.form.amount = value;

            },
            changeUnit(value) {

                if(value == 'day') {
                    this.form.term.unit = 'day';

                }else{
                    this.form.term.unit = 'month';
                }

            },

            toggleDatapick() {
                this.$refs.datepicker.opened = !this.$refs.datepicker.opened;
            },
            limitMessage() {
                if(this.form.count=='')
                    return "Укажите срок."

                if(this.form.count<31&&this.form.amount>30000&&this.form.term.unit==='day')
                    return "Минимальный срок - 31 дней"
                else if(this.form.count>365&&this.form.amount>30000&&this.form.term.unit==='day')
                    return "Максимальный срок - 365 дней."
                else if(this.form.count>30&&this.form.amount<=30000&&this.form.term.unit==='day')
                    return "Максимальный срок - 30 дней"
                else if(this.form.count<5&&this.form.amount<=30000&&this.form.term.unit==='day')
                    return "Минимальный срок - 5 дней"
                else if(this.form.count>12&&this.form.amount>30000&&this.form.term.unit==='month')
                    return "Максимальный срок - 12 месяцев."
                else if(this.form.count>1&&this.form.amount<=30000&&this.form.term.unit==='month')
                    return "Максимальный срок - 1 месяц "
                else
                    return ''
            },
            updGender(){
                this.form.gender =this.$root.globalGender
                return false
            },
            updCityKladr(){
                this.form.cityKladr =this.$root.globalCityKladr
                this.form.region =this.$root.globalRegion
                return false
            }
        }
    }
</script>
